import gsap from "gsap";
import { CSSPlugin } from "gsap/CSSPlugin";
import { Draggable } from "gsap/Draggable";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import bgBackCard from "../assets/images/bg_back_card.png";
import whiteLines from "../assets/images/detail_lines.png";
import whiteLinesLast from "../assets/images/detail_lines_last.png";
import Icon from "../components/General/Icon/Icon";
import { device, fontHeading } from "../components/Layout/theme";
import { ChampID } from "../data/champs";
import { FinalQuestion, QuestionID } from "../data/questions";
import { getIntl } from "../helpers/rioti18n";
import { useQuestion } from "../hooks/useQuestion";
import { ChoiceDirection } from "../pages";
import { push } from "../utils/gtm";

const FlipCard = styled.div`
  position: absolute;
  width: 70vw;
  height: 104vw;
  z-index: 1;
  perspective: 2000px;
  transform-origin: 50% 50% 200px;

  @media ${device.minWidth768} {
    width: 380px;
    height: 560px;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    top: 52px;
    left: 100px;
  }
`;

const CardInner = styled.div`
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  position: relative;
`;

const CardBack = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(${bgBackCard});
  background-size: cover;
  backface-visibility: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 93%, 90% 100%, 0 100%);
`;

const CardFront = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: 100% auto;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  clip-path: polygon(0 0, 100% 0, 100% 93%, 90% 100%, 0 100%);

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    backface-visibility: visible;
  }
`;

const QuestionCard = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:hover {
    &::after {
      animation: hoverEffect 1.2s forwards;
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      57deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.4) 50%,
      rgba(255, 255, 255, 0) 70%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  @keyframes hoverEffect {
    from {
      top: 100%;
      left: -100%;
    }
    to {
      top: -100%;
      left: 100%;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% - 35px);
    height: calc(100% - 30px);
    transform: translate(-50%, -50%);
    background: url(${whiteLines});
    background-size: 100% 100%;
    pointer-events: none;
    z-index: 1;
  }

  .questionWrapper {
    ${fontHeading};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 10%;
    background: #141e3760;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-style: italic;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 1px;
    text-align: center;
    opacity: 0;

    h4 {
      width: 100%;
      display: block;
    }

    span {
      margin: 3rem 0;
    }
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    &::after {
      display: none;
    }
  }
`;

const FinalCard = styled.div`
  padding: 11px 9px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 10%;
    background: #141e3760;
  }

  button {
    ${fontHeading};
    width: calc(100% - 24px);
    height: calc((100% / 3) - 20px);
    color: #fff;
    text-transform: uppercase;
    font-style: italic;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 1px;
    text-align: center;
    z-index: 1;
    position: relative;
    overflow: hidden;
    padding-left: 20px;
    padding-right: 20px;

    @media ${device.minWidth768} {
      font-size: 24px;
      line-height: 30px;

      &:hover {
        &::before {
          opacity: 0.2;
        }
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: white;
      opacity: 0;
      clip-path: polygon(100% 0, 100% 81%, 92% 97%, 0 97%, 0 0);
      transition: opacity 0.2s ease;
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
      background: url(${whiteLinesLast});
      background-size: 100% 100%;
      pointer-events: none;
      z-index: 1;
    }

    &:focus {
      outline: none;
    }
  }
`;

type SwipeableCardProps = {
  questionID: QuestionID;
  onCompletedAnimation: (choice: ChoiceDirection) => void;
  setSelectedChampion: (champ: ChampID) => void;
  setSwiped: () => void;
  step: number;
};

const MAX_DRAG = 50;
const LIMIT_DRAG = 200;

export const SwipeableCard: React.FC<SwipeableCardProps> = ({
  questionID,
  onCompletedAnimation,
  setSelectedChampion,
  setSwiped,
  step,
}) => {
  const { getQuestion } = useQuestion();
  const question = getQuestion(questionID);
  const { isFinal } = question;
  const { t } = getIntl();

  const draggable = useRef<null | Draggable>(null);
  const dragElementRef = useRef<null | HTMLDivElement>(null);
  const cardInnerRef = useRef<null | HTMLDivElement>(null);
  const leftTextRef = useRef<null | HTMLHeadingElement>(null);
  const rightTextRef = useRef<null | HTMLHeadingElement>(null);
  const cardImageRef = useRef<null | HTMLImageElement>(null);

  const [cardImageLoaded, setCardImageLoaded] = useState(false);

  const onCompletedSwipe = useCallback(
    (choice: ChoiceDirection) => {
      onCompletedAnimation(choice);
    },
    [onCompletedAnimation],
  );

  useEffect(() => {
    gsap.registerPlugin(Draggable, CSSPlugin);
    if (!isFinal) {
      const setRot = gsap.utils.pipe(
        gsap.utils.clamp(-10, 10),
        gsap.quickSetter(dragElementRef.current, "rotation", "deg") as (T: number) => unknown,
      );
      const timeline = gsap.timeline({ paused: true });
      timeline.fromTo(leftTextRef.current, { opacity: 1, duration: 1 }, { opacity: 0 });
      timeline.fromTo(rightTextRef.current, { opacity: 0, duration: 1 }, { opacity: 1, delay: 1 });
      timeline.progress(0.5);
      const setProgress = (x: number): gsap.core.Timeline => timeline.progress(x);

      const onCompletedSelect = (choice: ChoiceDirection): void => {
        gsap.to(dragElementRef.current, {
          x: `${choice === "left" ? "-" : "+"}=300`,
          autoAlpha: 0,
          duration: 0.5,
          onComplete: () => onCompletedSwipe(choice),
        });
        push({ carousel: `${step + 1}_swipe_${choice}` });
      };

      const onUncompletedDrag = (x: number): void => {
        const d: gsap.core.Tween = gsap.to(dragElementRef.current, {
          x: 0,
          y: 0,
          rotation: 0,
          duration: 0.5,
          onUpdate: () => {
            setProgress(((1 - d.progress()) * x * 0.0001 * MAX_DRAG + 1) * 0.5);
          },
        });
      };
      const setTextTimelineTween = gsap.utils.pipe(
        gsap.utils.clamp(-MAX_DRAG, MAX_DRAG),
        gsap.utils.mapRange(-MAX_DRAG, MAX_DRAG, 0, 1),
        setProgress,
      );
      const limitDirection = (direction: "x" | "y"): ((T: number) => void) =>
        gsap.utils.pipe(
          gsap.utils.clamp(-LIMIT_DRAG, LIMIT_DRAG),
          gsap.quickSetter(dragElementRef.current, direction, "px") as (T: number) => unknown,
        );
      const limitX = limitDirection("x");
      const limitY = limitDirection("y");
      [draggable.current] = Draggable.create(dragElementRef.current, {
        zIndexBoost: false,
        onDrag: () => {
          setSwiped();
          const { x, y } = draggable.current as Draggable;
          setRot(x * 0.1);
          setTextTimelineTween(x);
          limitX(x);
          limitY(y);
        },
        onDragEnd: () => {
          const { x } = draggable.current as Draggable;
          if (x < -MAX_DRAG) {
            onCompletedSelect("left");
          } else if (x > MAX_DRAG) {
            onCompletedSelect("right");
          } else {
            onUncompletedDrag(x);
          }
        },
      });
    }
  }, [dragElementRef, isFinal]);

  const onImageLoad = useCallback(() => {
    setCardImageLoaded(true);
  }, []);

  useEffect(() => {
    if ((cardImageRef.current?.complete || cardImageLoaded) && question.id) {
      gsap.to(cardInnerRef.current, { rotateY: "180deg", duration: 0.6 });
      gsap.to(cardInnerRef.current, { scale: 1.25, duration: 0.2 });
      gsap.to(cardInnerRef.current, { scale: 1, duration: 0.4, delay: 0.2 });
    }
  }, [cardImageLoaded]);
  const selectChamp = (index: 0 | 1 | 2): void => {
    push({ carousel: `4_final_card${index + 1}` });
    setSelectedChampion((question as FinalQuestion).answers[index].champion);
  };
  return (
    <FlipCard ref={dragElementRef}>
      <CardInner ref={cardInnerRef} data-testid="hero:card">
        <CardBack />
        <CardFront>
          <img src={`/images/cards/${questionID}.jpg`} onLoad={onImageLoad} ref={cardImageRef} />
          {isFinal ? (
            <FinalCard>
              <button
                data-interaction-id="4_final_card1"
                onClick={(): void => selectChamp(0)}
                data-testid="hero:final-card:option-0-button"
              >
                {t(`answer.${question.id}-l`)}
              </button>
              <button
                data-interaction-id="4_final_card2"
                onClick={(): void => selectChamp(1)}
                data-testid="hero:final-card:option-1-button"
              >
                {t(`answer.${question.id}-c`)}
              </button>
              <button
                data-interaction-id="4_final_card3"
                onClick={(): void => selectChamp(2)}
                data-testid="hero:final-card:option-2-button"
              >
                {t(`answer.${question.id}-r`)}
              </button>
            </FinalCard>
          ) : (
            <>
              <QuestionCard>
                <span className="questionWrapper" ref={leftTextRef}>
                  <Icon name="Diamond" size={12} />
                  <h4>{t(`answer.${question.id}-l`)}</h4>
                  <Icon name="Diamond" size={12} />
                </span>
                <span className="questionWrapper" ref={rightTextRef}>
                  <Icon name="Diamond" size={12} />
                  <h4>{t(`answer.${question.id}-r`)}</h4>
                  <Icon name="Diamond" size={12} />
                </span>
              </QuestionCard>
            </>
          )}
        </CardFront>
      </CardInner>
    </FlipCard>
  );
};
