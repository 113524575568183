import { useCallback, useMemo, useState } from "react";
import { getQuestionByID, QuestionID, QuestionWithID } from "../data/questions";

export const useQuestion = (): {
  questionID: QuestionID;
  setQuestionID: (T: QuestionID) => void;
  getQuestion: (T: QuestionID) => QuestionWithID;
  step: number;
} => {
  const [questionID, setQuestionID] = useState<QuestionID>("q");

  const getQuestion = useCallback((questionID: QuestionID): QuestionWithID => getQuestionByID(questionID), [
    questionID,
  ]);
  const step = useMemo((): number => {
    return questionID.replace(/-/g, "").length - 1;
  }, [questionID]);

  return {
    questionID,
    setQuestionID,
    getQuestion,
    step,
  };
};
