import gsap from "gsap";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const PagerStyled = styled.ul`
  position: absolute;
  bottom: 0;
  text-align: center;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: row;

  li {
    position: relative;
    margin: 0 3px 0 2px;
    width: 50px;
    height: 7px;
    text-indent: -9999px;

    .outer {
      content: "";
      background: #141e37;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transform: skewX(-20deg);
      overflow: hidden;

      .inner {
        position: absolute;
        background: #32c8ff;
        height: 100%;
        display: block;
        width: 0;
        top: 0;
        left: 0;
      }
    }
  }
`;

type PagerProps = {
  step: number;
};

export const Pager: React.FC<PagerProps> = ({ step }) => {
  const parentRef = useRef<null | HTMLUListElement>(null);
  const timelines = useRef<Array<gsap.core.Timeline>>([]);

  useEffect(() => {
    if (!parentRef.current) return;
    const { children } = parentRef.current;

    [...children].forEach((el) => {
      const {
        children: [
          {
            children: [inner],
          },
        ],
      } = el;
      const tl: gsap.core.Timeline = gsap.timeline({ paused: true });
      tl.to(inner, { width: "100%", duration: 0.1, delay: 0.5 });
      tl.to(inner, { left: "100%", duration: 0.1 });
      tl.set(inner, { width: "0%", left: 0 });
      tl.to(inner, { width: "100%", duration: 0.2 });
      timelines.current.push(tl);
    });
  }, [parentRef.current]);
  useEffect(() => {
    timelines.current[step].play();
  }, [step, parentRef.current]);
  return (
    <PagerStyled ref={parentRef}>
      {Array(4)
        .fill(null)
        .map((_, index) => (
          <li key={index.toString()}>
            <span className="outer">
              <span className="inner" />
            </span>
            {index + 1}
          </li>
        ))}
    </PagerStyled>
  );
};
