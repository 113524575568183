import { navigate } from "gatsby-plugin-intl";
import gsap from "gsap";
import React, { useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import LoaderImg from "../assets/images/champion-found.png";
import Icon from "../components/General/Icon/Icon";
import { fontHeading, MainTheme } from "../components/Layout/theme";
import { ChampID } from "../data/champs";
import { getIntl } from "../helpers/rioti18n";
import { device } from "./Layout/theme";

const OverlayBackground = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(20, 30, 55, 0.9);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  color: ${MainTheme.colorElectricBlue};
`;

const OverlayWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  clip-path: polygon(100% 0, 100% 100%, 52% 100%, 54.6% 96.8%, 50% 92%, 45% 97%, 48% 100%, 0 100%, 0 0);
`;

const OverlayLoader = styled.div`
  width: 240px;
  height: 240px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &::before {
    content: "";
    position: absolute;
    background: ${MainTheme.colorElectricBlue};
    width: 7px;
    height: 8px;
    left: 50%;
    top: calc(50% + 113px);
    transform: translate(-50%, -50%) rotate(-45deg);
    animation: appear 0.2s ease forwards 0.8s;
    opacity: 0;

    @media ${device.minWidth768} {
      width: 15px;
      height: 15px;
      left: calc(50% - 1px);
      top: calc(50% + 226px);
    }

    @keyframes appear {
      to {
        opacity: 1;
      }
    }
  }

  @media ${device.minWidth768} {
    width: 480px;
    height: 480px;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
`;

const LoaderCopy = styled.span`
  ${fontHeading}
  position: relative;
  display: block;
  text-transform: uppercase;
  font-style: italic;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  width: 150px;

  @media ${device.minWidth768} {
    font-size: 44px;
    line-height: 45px;
    width: 310px;
  }

  ${(props): string =>
    props.theme.locale === "ja-jp"
      ? `
    width:130px;
    max-width:130px;
    font-size:16px;
      @media ${device.minWidth768} {
        font-size: 28px;
        line-height: 45px;
        max-width: 240px;
      }
    `
      : ``};
`;

const LoaderContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: iconPosition 0.3s ease-in-out forwards 2s;

  @keyframes iconPosition {
    to {
      top: 38%;
    }
  }

  span:nth-of-type(1) {
    width: 60px;
    height: 60px;
    position: relative;
    animation: iconScale 0.3s ease-in-out forwards 2s;

    @media ${device.minWidth768} {
      width: 100px;
      height: 100px;
    }
  }

  @keyframes iconScale {
    to {
      transform: scale(0.4);
    }
  }
`;

const CopyWrapper = styled.div`
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, 0);
  transform-origin: top center;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: copy 0.4s ease forwards 2.1s;
  width: 100%;

  @keyframes copy {
    to {
      top: 46%;
      opacity: 1;
    }
  }
`;

type ChampionFoundOverlay = {
  selectedChampion: null | ChampID;
};

export const ChampionFoundOverlay: React.FC<ChampionFoundOverlay> = ({ selectedChampion }) => {
  const { t } = getIntl();
  const loaderRef = useRef<HTMLDivElement | null>(null);
  const loaderBackground = useRef<HTMLDivElement | null>(null);
  const navigateTo = useCallback((champion) => navigate(t(`champ.${champion}.url`)), [navigate]);

  useEffect(() => {
    if (!loaderRef.current || !loaderBackground.current) return;
    const [
      {
        children: [loaderSvg],
      },
    ] = [...loaderRef.current.children];

    const tl = gsap.timeline({
      onComplete: () => gsap.delayedCall(0.5, () => navigateTo(selectedChampion)),
    });

    tl.set(loaderSvg.childNodes[1], {
      strokeDasharray: 310,
      strokeDashoffset: 310,
      rotation: 90,
      transformOrigin: "50% 50%",
    });
    tl.to(loaderBackground.current, { autoAlpha: 1, duration: 0.6 }, 0);
    tl.from(loaderRef.current, { autoAlpha: 0, y: -40, duration: 0.4 }, 0.7);
    tl.to(loaderSvg.childNodes[1], { strokeDashoffset: 0, duration: 1.5, ease: "power0.none" }, "-=0.3");
  }, [selectedChampion]);

  if (!selectedChampion) return null;
  return (
    <OverlayBackground ref={loaderBackground}>
      <OverlayLoader>
        <OverlayWrapper ref={loaderRef}>
          <Icon name="LoaderFrame" />
          <img src={LoaderImg} alt="loader-image" />
          <LoaderContent>
            <IconWrapper>
              <Icon name="Helm" />
            </IconWrapper>
            <CopyWrapper>
              <LoaderCopy>{t("homepage.overlay")}</LoaderCopy>
            </CopyWrapper>
          </LoaderContent>
        </OverlayWrapper>
      </OverlayLoader>
    </OverlayBackground>
  );
};
