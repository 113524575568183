import { ChampID } from "./champs";

type BaseAnswer = {};
export type QuestionID =
  | "q0"
  | "q"
  | "q-l"
  | "q-l-l"
  | "q-l-l-l"
  | "q-l-l-r"
  | "q-l-r"
  | "q-l-r-l"
  | "q-l-r-r"
  | "q-r"
  | "q-r-l"
  | "q-r-l-l"
  | "q-r-l-r"
  | "q-r-r"
  | "q-r-r-l"
  | "q-r-r-r";

type NormalAnswer = BaseAnswer & {
  toStep: QuestionID;
};

export type FinalAnswer = BaseAnswer & {
  champion: ChampID;
};

export type Answer = NormalAnswer | FinalAnswer;

type BaseQuestion = {
  isFinal?: boolean;
};

type NormalQuestion = BaseQuestion & {
  answers: [NormalAnswer, NormalAnswer];
};

export type FinalQuestion = BaseQuestion & {
  isFinal: true;
  answers: [FinalAnswer, FinalAnswer, FinalAnswer];
};

export type Question = NormalQuestion | FinalQuestion;

export type QuestionWithID = Question & { id: QuestionID };

export const questions: Record<QuestionID, Question> = {
  q0: {
    answers: [{ toStep: "q" }, { toStep: "q" }],
  },
  q: {
    answers: [
      {
        toStep: "q-l",
      },
      {
        toStep: "q-r",
      },
    ],
  },
  "q-l": {
    answers: [
      {
        toStep: "q-l-l",
      },
      {
        toStep: "q-l-r",
      },
    ],
  },
  "q-l-l": {
    answers: [
      {
        toStep: "q-l-l-l",
      },
      {
        toStep: "q-l-l-r",
      },
    ],
  },
  "q-l-l-l": {
    isFinal: true,
    answers: [{ champion: "janna" }, { champion: "sona" }, { champion: "nami" }],
  },
  "q-l-l-r": {
    isFinal: true,

    answers: [
      {
        champion: "amumu",
      },
      {
        champion: "blitzcrank",
      },
      {
        champion: "braum",
      },
    ],
  },
  "q-l-r": {
    answers: [
      {
        toStep: "q-l-r-l",
      },
      {
        toStep: "q-l-r-r",
      },
    ],
  },
  "q-l-r-l": {
    isFinal: true,
    answers: [{ champion: "jinx" }, { champion: "varus" }, { champion: "miss_fortune" }],
  },
  "q-l-r-r": {
    isFinal: true,
    answers: [{ champion: "ziggs" }, { champion: "lux" }, { champion: "seraphine" }],
  },
  "q-r": {
    answers: [
      {
        toStep: "q-r-l",
      },
      {
        toStep: "q-r-r",
      },
    ],
  },
  "q-r-r": {
    answers: [
      {
        toStep: "q-r-r-l",
      },
      {
        toStep: "q-r-r-r",
      },
    ],
  },
  "q-r-r-l": {
    isFinal: true,
    answers: [
      {
        champion: "garen",
      },
      {
        champion: "vi",
      },
      { champion: "jax" },
    ],
  },
  "q-r-r-r": {
    isFinal: true,
    answers: [
      {
        champion: "malphite",
      },
      { champion: "alistar" },
      { champion: "jarvan" },
    ],
  },
  "q-r-l": {
    answers: [
      {
        toStep: "q-r-l-l",
      },
      {
        toStep: "q-r-l-r",
      },
    ],
  },
  "q-r-l-l": {
    isFinal: true,
    answers: [{ champion: "ahri" }, { champion: "twisted_fate" }, { champion: "annie" }],
  },
  "q-r-l-r": {
    isFinal: true,
    answers: [{ champion: "zed" }, { champion: "fizz" }, { champion: "akali" }],
  },
};

export const getQuestionByID = (id: QuestionID): QuestionWithID => ({ ...questions[id], id });
