import React, { useRef } from "react";
import styled from "styled-components";

const StyledDetailLine = styled.div`
  position: absolute;
  top: 0;
  left: 4px;
  bottom: 0;
  border-left: 3px solid white;
  animation: detailLine;
  animation-duration: 1s;
  animation-delay: 3s;

  .line-with-dash:nth-of-type(1) {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    top: 0;
    height: 40%;
    max-height: 115px;
    left: -0.7rem;
    background: white;
  }

  .line-with-dash:nth-of-type(2) {
    content: "";
    position: absolute;
    right: 0;
    background: white;
    top: 5px;
    height: 42%;
    max-height: 120px;
    left: -0.7rem;
    transform: skewY(45deg);
  }

  ${(props): string =>
    props.theme.isRTL
      ? `
          left: initial;
          right: -3.2rem;

          .line-with-dash:nth-of-type(1) {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: -0.6rem;
            bottom: 60%;
            background: white;
          }

          .line-with-dash:nth-of-type(2) {
            content: "";
            position: absolute;
            top: 2rem;
            right: -0.6rem;
            left: 0;
            background: white;
            bottom: calc(60% - 2rem);
            transform: skewY(-45deg);
          }
        `
      : ``}
`;

export const DetailLine: React.FC = () => {
  const styledLineRef = useRef<HTMLDivElement | null>(null);

  return (
    <StyledDetailLine ref={styledLineRef}>
      <span className={`line-with-dash`}></span>
      <span className={`line-with-dash`}></span>
    </StyledDetailLine>
  );
};
