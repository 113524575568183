import gsap from "gsap";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { device, fontHeading } from "../../../components/Layout/theme";
import { DetailLine } from "../DetailLine/DetailLine";

const LineWrapper = styled.div`
  position: absolute;
  left: -3.2rem;
  top: 0;
  height: 0;
  width: 8px;
  overflow: hidden;
`;

const StyledTitle = styled.h2<{ theme: { locale: string } }>`
  letter-spacing: 1px;
  ${fontHeading};
  ${(props): string =>
    props.theme.locale === "ru-ru"
      ? `
      font-size: 2.4rem;
      line-height: 2.4rem;
    `
      : `
      font-size: 32px;
      line-height: 30px;
    `};

  @media ${device.minWidth768} {
    letter-spacing: 2px;
    ${(props): string =>
      props.theme.locale === "ru-ru"
        ? `
        font-size: 4.8rem;
        line-height: 4.8rem;
      `
        : `
        font-size: 80px;
        line-height: 80px;
      `};
  }
`;

const StyledEyebrow = styled.h3`
  ${fontHeading};
  font-size: 14px;
  letter-spacing: 0.8px;

  @media ${device.minWidth768} {
    font-size: 19px;
    margin-bottom: 10px;
    letter-spacing: 1px;
  }
`;

type TextBlockProps = {
  eyebrow: string;
  title: string;
  as?: React.ElementType;
};

const TextBlock: React.FC<TextBlockProps> = ({ eyebrow, title, as }) => {
  const [currentTitle, setCurrentTitle] = useState<string>(title);
  const isFirstTime = useRef(true);
  const titleRef = useRef<HTMLElement | null>(null);
  const eyebrowRef = useRef<HTMLHeadingElement | null>(null);
  const lineRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!isFirstTime.current) {
      gsap.to(titleRef.current, {
        autoAlpha: 0,
        duration: 0.37,
        x: 50,
        delay: 0.45,
        ease: "expo.in",
        onComplete: () => {
          setCurrentTitle(title);
          gsap.fromTo(
            titleRef.current,
            { autoAlpha: 0, x: -50 },
            { autoAlpha: 1, x: 0, duration: 1, ease: "expo.out" },
          );
        },
      });
    } else {
      gsap.fromTo(
        [titleRef.current, eyebrowRef.current],
        { autoAlpha: 0, x: -25 },
        { autoAlpha: 1, x: 0, duration: 1, delay: 1.4, ease: "power4.out" },
      );
    }
    isFirstTime.current = false;
  }, [title]);

  useEffect(() => {
    gsap.to(lineRef.current, {
      height: "0%",
      duration: 0.2,
    });
  }, [title]);

  useEffect(() => {
    gsap.to(lineRef.current, {
      height: "100%",
      duration: 0.2,
      delay: 1,
    });
  }, [title]);

  return (
    <>
      <LineWrapper ref={lineRef}>
        <DetailLine />
      </LineWrapper>
      <StyledEyebrow ref={eyebrowRef} data-testid="hero:subtitle">
        {eyebrow}
      </StyledEyebrow>
      <StyledTitle as={as} ref={titleRef} data-testid="hero:title">
        {currentTitle}
      </StyledTitle>
    </>
  );
};

export default TextBlock;
