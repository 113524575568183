import gsap from "gsap";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { CardStack } from "../components/CardStack";
import { ChampionFoundOverlay } from "../components/ChampionFoundOverlay";
import TextBlock from "../components/General/TextBlock/TextBlock";
import Layout from "../components/Layout";
import { ContentWrapper } from "../components/Layout/style";
import { device, fontHeading } from "../components/Layout/theme";
import { Pager } from "../components/Pager";
import SEO from "../components/seo";
import { SwipeableCard } from "../components/SwipeableCard";
import { ChampID } from "../data/champs";
import Locale from "../data/contexts/riotLocale";
import { QuestionID } from "../data/questions";
import { getIntl } from "../helpers/rioti18n";
import { useQuestion } from "../hooks/useQuestion";

export type ChoiceDirection = "left" | "right";

const QuizWrapper = styled(ContentWrapper)<{ touchEnabled: boolean }>`
  display: flex;
  flex-direction: column;
  pointer-events: ${({ touchEnabled }): string => (touchEnabled ? "all" : "none")};

  @media ${device.minWidth768} {
    height: calc(100vh - 8rem);
    flex-direction: row;
    align-items: center;
    padding: 0 2.8rem 5rem;
  }
`;

const QuestionWrapper = styled.div`
  position: relative;
  width: 100%;
  align-self: flex-start;
  padding: 0;

  @media ${device.minWidth768} {
    width: 50%;
    top: 20%;
    padding: 0;
  }
`;

const CardsWrapper = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;

  @media ${device.minWidth768} {
    width: 50%;
  }

  &::before,
  &::after {
    content: "";
    background: #fff;
    opacity: 0.15;
    position: absolute;
    top: 110px;
    transform-origin: bottom center;
    z-index: -1;
    width: 70vw;
    height: 104vw;

    @media ${device.minWidth768} {
      width: 380px;
      height: 560px;
    }
  }

  &::before {
    transform: rotate(-10deg);
    left: 0;
  }

  &::after {
    transform: rotate(10deg);
    right: 0;
  }
`;

const CardsPagerWrapper = styled.div`
  height: calc(104vw + 75px);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.minWidth768} {
    height: calc(580px + 80px);
  }
`;

const SwipeIndicator = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 160px;
  height: 160px;
  transform: translate(-50%, -50%);
  background: #141e37;
  border-radius: 50%;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
  opacity: 0;

  span {
    ${fontHeading};
    text-transform: uppercase;
    font-style: italic;
    font-size: 23px;
    line-height: 30px;
    display: block;
    text-align: center;
    letter-spacing: 1px;
    width: 100%;
  }
`;

const IndexPage: React.FC = () => {
  const { setQuestionID, step, questionID } = useQuestion();
  const swiped = useRef(false);
  const [selectedChampion, setSelectedChampion] = useState<null | ChampID>(null);
  const { t } = getIntl();
  const indicatorRef = useRef<null | HTMLDivElement>(null);
  const [touchEnabled, setTouchEnabled] = useState(false);
  const locale = useContext(Locale);

  useEffect(() => {
    if (!indicatorRef.current) return;
    const tl = gsap.timeline();
    gsap.delayedCall(1.5, () => {
      if (!swiped.current) {
        tl.fromTo(
          indicatorRef.current,
          { autoAlpha: 0 },
          {
            autoAlpha: 1,
            delay: 1.5,
            duration: 0.3,
            onComplete: () => {
              setTouchEnabled(true);
            },
          },
        );
      }
    });
  }, [indicatorRef]);

  const onCompletedSwipeAnimation = useCallback(
    (choice: ChoiceDirection) => {
      setQuestionID(`${questionID}-${choice[0]}` as QuestionID);
    },
    [setQuestionID, questionID],
  );

  const setSwiped = useCallback(() => {
    if (!swiped.current) {
      gsap.to(indicatorRef.current, { autoAlpha: 0, duration: 0.3 });
      swiped.current = true;
    }
  }, [swiped.current]);

  return (
    <Layout>
      <SEO title={t("homepage.eyebrow")} description={t("general.description")} />
      <QuizWrapper touchEnabled={touchEnabled}>
        <QuestionWrapper>
          <TextBlock eyebrow={t("homepage.eyebrow")} title={t(`question.${questionID}`)} />
        </QuestionWrapper>

        <CardsWrapper>
          {step < 3 && <CardStack />}
          <CardsPagerWrapper>
            <SwipeableCard
              key={questionID}
              questionID={questionID}
              onCompletedAnimation={onCompletedSwipeAnimation}
              setSelectedChampion={setSelectedChampion}
              setSwiped={setSwiped}
              step={step}
            />
            <Pager step={step} />
            <SwipeIndicator ref={indicatorRef}>
              <span>{t("homepage.card-overlay")}</span>
              {locale === "zh-tw" ? <span>或右滑</span> : <span>&lt; {t("homepage.or")} &gt;</span>}
            </SwipeIndicator>
          </CardsPagerWrapper>
        </CardsWrapper>
      </QuizWrapper>
      <ChampionFoundOverlay selectedChampion={selectedChampion} />
    </Layout>
  );
};
export default IndexPage;
