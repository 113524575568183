import React from "react";
import styled from "styled-components";
import bgBackCard from "../assets/images/bg_back_card.png";
import { device } from "../components/Layout/theme";

const FlipCard = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70vw;
  height: 104vw;

  @media ${device.minWidth768} {
    width: 380px;
    height: 560px;
  }
`;

const CardBack = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(${bgBackCard});
  background-size: cover;
  backface-visibility: hidden;

  clip-path: polygon(0 0, 100% 0, 100% 93%, 90% 100%, 0 100%);
`;
export const CardStack: React.FC = () => {
  return (
    <FlipCard>
      <CardBack />
    </FlipCard>
  );
};
